<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          ref="gridItem"
          title="평가항목"
          tableId="gridItem"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :editable="editable"
          :isExcelDown="false"
          :columns="gridItem.columns"
          :data="gridItem.data"
          @rowClick="rowItemClick"
        >
        <template slot="table-button">
          <div>
            <c-select
              type="none"
              :comboItems="revisionItems"
              itemText="codeName"
              itemValue="code"
              label=""
              name="sysRevision"
              @input="getItemList"
              v-model="searchParam.sysRevision">
            </c-select>
          </div>
        </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridIType"
          title="평가항목별 구분"
          tableId="gridIType"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :editable="editable"
          :isExcelDown="false"
          :columns="gridIType.columns"
          :data="gridIType.data"
          selection="multiple"
          rowKey="selfInspectionItemTypeId"
        >
          <template slot="table-button">
            <div>
              <q-btn-group outline>
                <c-btn label="" v-if="editable && selectedItemRow.selfInspectionItemMstId && revisionable" icon="add" @btnClicked="addTypeRow" />
                <c-btn label="" v-if="editable && selectedItemRow.selfInspectionItemMstId && revisionable" icon="remove" @btnClicked="removeTypeRow" />
                <c-btn label="" v-if="editable && selectedItemRow.selfInspectionItemMstId && revisionable" icon="save" @btnClicked="saveTypeRow" />
              </q-btn-group>
            </div>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                outline square
                :clickable="true"
                color="blue"
                text-color="white"
                style="margin-top:3px !important;"
                @click.stop="() => { rowTypeClick(props.row) }">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="gridCheck"
          title="체크리스트"
          tableId="gridCheck"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :editable="editable"
          :isExcelDown="false"
          :columns="gridCheck.columns"
          :data="gridCheck.data"
          selection="multiple"
          rowKey="selfInspectionItemTypeItemId"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip v-if="selectedTypeRow.selfInspectionItemTypeId" outline square>
                  {{ selectedTypeRow.itemTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <div>
              <q-btn-group outline>
              <c-btn label="추가" v-if="editable && selectedTypeRow.selfInspectionItemTypeId && revisionable" icon="add" @btnClicked="addCheckRow" />
              <c-btn label="삭제" v-if="editable && selectedTypeRow.selfInspectionItemTypeId && revisionable" icon="remove" @btnClicked="removeCheckRow" />
              <c-btn label="저장" v-if="editable && selectedTypeRow.selfInspectionItemTypeId && revisionable" icon="save" @btnClicked="saveCheckRow" />
              </q-btn-group>
            </div>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'compliance-item',
  data() {
    return {
      gridItem: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      gridIType: {
        columns: [
          {
            required: true,
            name: 'itemTypeName',
            field: 'itemTypeName',
            label: '구분',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            type: 'number',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'custom1',
            field: 'custom1',
            label: '',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      gridCheck: {
        columns: [
          {
            required: true,
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            type: 'number',
            style: 'width: 60px',
            sortable: false,
          },
          {
            required: true,
            name: 'itemName',
            field: 'itemName',
            label: '체크리스트',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
      },
      selectedItemRow: {},
      selectedTypeRow: {},
      editable: true,
      listItemUrl: '',
      listTypeUrl: '',
      listCheckUrl: '',
      saveTypeUrl: '',
      saveCheckUrl: '',
      deleteTypeUrl: '',
      deleteCheckUrl: '',
      revisionUrl: '',
      lastRevision: null,
      revisionable: true,
      searchParam: {
        sysRevision: null,
      },
      revisionItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.revisionUrl = selectConfig.sop.pca.item.revs.item;
      this.listItemUrl = selectConfig.sop.pca.item.revs.url;
      this.listTypeUrl = selectConfig.sop.pca.type.list.url;
      this.listCheckUrl = selectConfig.sop.pca.typeitem.list.url;
      this.saveTypeUrl = transactionConfig.sop.pca.type.save.url;
      this.deleteTypeUrl = transactionConfig.sop.pca.type.delete.url;
      this.saveCheckUrl = transactionConfig.sop.pca.typeitem.save.url;
      this.deleteCheckUrl = transactionConfig.sop.pca.typeitem.delete.url;
      this.getRevision();
    },
    getRevision() {
      this.$http.url = this.revisionUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.revisionItems = _result.data;
        this.searchParam.sysRevision = this.revisionItems[0].code;
        this.lastRevision = this.revisionItems[0].code;
        this.getItemList();
      },);
    },
    getItemList() {
      if (this.lastRevision == this.searchParam.sysRevision) {
        this.revisionable = true;
      } else {
        this.revisionable = false;
      }
      this.$http.url = this.listItemUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridItem.data = _result.data;
        this.gridIType.data = [];
        this.gridCheck.data = [];
      },);
    },
    getTypeList(_selfInspectionItemMstId) {
      this.$http.url = this.listTypeUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionItemMstId: _selfInspectionItemMstId
      }
      this.$http.request((_result) => {
        this.selectedTypeRow = [];
        this.gridIType.data = _result.data;
        this.gridCheck.data = [];
        if (this.gridIType.data.length === 1) {
          this.rowTypeClick({selfInspectionItemTypeId: this.gridIType.data[0].selfInspectionItemTypeId, itemTypeName: this.gridIType.data[0].itemTypeName});
        }
      },);
    },
    getCheckList(_selfInspectionItemTypeId) {
      this.$http.url = this.listCheckUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        selfInspectionItemTypeId: _selfInspectionItemTypeId
      }
      this.$http.request((_result) => {
        this.gridCheck.data = _result.data;
      },);
    },
    rowItemClick(row) {
      this.selectedItemRow = row;
      this.getTypeList(row.selfInspectionItemMstId);
    },
    rowTypeClick(row) {
      this.selectedTypeRow = row;
      this.getCheckList(row.selfInspectionItemTypeId);
    },
    addTypeRow() {
      this.selectedTypeRow = {};
      this.gridCheck.data = [];
      this.gridIType.data.push({
        editFlag: 'C',
        selfInspectionItemMstId: this.selectedItemRow.selfInspectionItemMstId,  // 자체감사 평가항목 일련번호
        selfInspectionItemTypeId: uid(),  // 자체감사 평가항목별 구분 일련번호
        itemTypeName: '',  // 자체감사 평가항목별 구분명
        sortOrder: this.gridIType.data.length + 1,  // 순번
        delFlag: 'N',  // 삭제여부
      });
    },
    saveTypeRow() {
      if(this.$comm.validTable(this.gridIType.columns, this.gridIType.data)) {
        let saveData = this.gridIType.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveTypeUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getTypeList(this.selectedItemRow.selfInspectionItemMstId);
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeTypeRow() {
      let selectData = this.$refs['gridIType'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteTypeUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.gridIType.data = this.$_.reject(this.gridIType.data, item);
              })
              this.$refs['gridIType'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addCheckRow() {
      this.gridCheck.data.push({
        editFlag: 'C',
        selfInspectionItemMstId: this.selectedItemRow.selfInspectionItemMstId,  // 자체감사 평가항목 일련번호
        selfInspectionItemTypeId: this.selectedTypeRow.selfInspectionItemTypeId,  // 자체감사 평가항목별 구분 일련번호
        selfInspectionItemTypeItemId: uid(),  // 자체감사 감사항목 일련번호
        itemTypeName: '',  // 자체감사 평가항목별 구분명
        sortOrder: this.gridCheck.data.length + 1,  // 순번
        delFlag: 'N',  // 삭제여부
      });
    },
    saveCheckRow() {
      if(this.$comm.validTable(this.gridCheck.columns, this.gridCheck.data)) {
        let saveData = this.gridCheck.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveCheckUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getCheckList(this.selectedTypeRow.selfInspectionItemTypeId);
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeCheckRow() {
      let selectData = this.$refs['gridCheck'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteCheckUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.gridCheck.data = this.$_.reject(this.gridCheck.data, item);
              })
              this.$refs['gridCheck'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
<style lang="sass">
.gridbtntop 
  .customSelect
    padding-bottom: 0px !important
    .q-field__inner
      padding-bottom: 0px !important
    .q-field__control, .customSelect.q-field--dense .q-field__marginal
      height: 24px !important
      min-height: 24px !important

</style>
